export interface Claim {
  id: string;
  claimId: string;
  encounterId: string;
  payerId: string;
  servicesId: [];
  encounter?: any;
  facilityClaim: FacilityClaim;
  payer?: any;
  secondaryPayer?: any;
  tertiaryPayer?: any;
  facilityId?: any;
  status?: number;
}

export interface FacilityClaim {
  typeOfBill: string;
  admissionType: string;
  admissionSource: string;
  dischargeStatus: string;
  documentControlNumber: string;
  remarksField: string;
  billingProviderId: string;
  facilityBillingProviderId?: string;
  attendingProviderId?: string;
  taxId?: any;
}

export enum ClaimStatusUser {
  Submitted = 0,
  SubmissionFailed = 1,
  PendingSubmission = 2,
  Resubmitted = 3,
  OnHold = 4,
  Closed = 5,
  Voided = 6,
  Created = 7,
  VoidFailed = 8,
  Transferred = 9 
};

export enum ClaimActions
{
    Submit = 0,
    Transfer = 2,
    Void = 3,
    Hold = 4,
    Close = 5,
}

export const defaultReasonCodesgroupCode = "CO";
export const defaultReasonCode = "45";