import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/claimAdjustmentGroupCode";

export async function searchDictCAGC(request) {
  return await ApiService.post(url + "/searchDict", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}